import React, { Component } from 'react'
import Api from '../../Services/Cloud';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from './Modal';
import DataTable from '../../components/DataTable';
import { parseMoney, removeAcento } from '../../Util/Functions';
import Loading from '../../components/Loading';
import $ from 'jquery';
import ObservationModal from './ObservationModal';
import CallModal from './CallModal';

let userType = localStorage.getItem('userType');

const columns = [
    { title: "Responsável", render: (data, type, row) => { if (type === 'display') { return row.name } else { return removeAcento(row.name) } } },
    { title: "Aluno", render: (data, type, row) => { if (type === 'display') { return row.has_dependent ? row.minor_name : row.name } else { return row.has_dependent ? removeAcento(row.minor_name) : removeAcento(row.name) } } },
    { title: "Cadastrado em", render: (data, type, row) => { return moment(row.createdAt).format('DD/MM/YYYY') } },
    { title: "Pedido em", render: (data, type, row) => { return moment(row.Order ? row.Order.updatedAt : row.createdAt).format('DD/MM/YYYY') } },
    { title: 'Telefone', render: (data, type, row) => { return row.phone ? row.phone : '' } },
    { title: 'Whatsapp', render: (data, type, row) => { return row.whatsapp_phone ? row.whatsapp_phone : '' } },
    { title: 'Fone Recados', render: (data, type, row) => { return row.friend_phone ? row.friend_phone : '' } },
    { title: 'Email', render: (data, type, row) => { return row.email ? row.email : '' } },
    { title: 'CPF', render: (data, type, row) => { return row.cpf.replace(/\D/g, '') } },
    { title: 'Mensalidade', render: (data, type, row) => { return row.Order ? parseMoney(row.Order.scholarship.full_price) : 'N/D' } },
    { title: 'Desconto', render: (data, type, row) => { return row.Order ? row.Order.scholarship.discount + '%' : 'N/D' } },
    { title: 'Valor c/ desconto', render: (data, type, row) => { return row.Order ? parseMoney(row.Order.scholarship.final_price) : 'N/D' } },
    { title: 'Instituição', render: (data, type, row) => { if (type === 'display') { return row.Order ? row.Order.scholarship.institution.name : row.scholarship ? row.scholarship.institution.name : 'N/D' } else { return row.Order ? removeAcento(row.Order.scholarship.institution.name) : row.scholarship ? removeAcento(row.scholarship.institution.name) : 'N/D' } } },
    { title: 'Curso', render: (data, type, row) => { if (type === 'display') { return row.Order ? row.Order.scholarship.course.name : row.scholarship ? row.scholarship.course.name : 'N/D' } else { return row.Order ? removeAcento(row.Order.scholarship.course.name) : row.scholarship ? removeAcento(row.scholarship.course.name) : 'N/D' } } },
    { title: 'Turno', render: (data, type, row) => { return row.Order ? row.Order.scholarship.shift : row.scholarship ? row.scholarship.shift : 'N/D' } },
    { title: 'Modalidade', render: (data, type, row) => { return row.Order ? row.Order.scholarship.type : row.scholarship ? row.scholarship.type : 'N/D' } },
    { title: 'CCP', render: (data, type, row) => { return row.partner ? row.partner.name : row.ccp } },
    {
        title: 'Situação',
        render: (data, type, row) => {
            if(row.name === "JOANA D'ARC ANDRADE PEREIRA") {
                // if(row.Order === null || row.Order.payments.length === 0) {
                console.log(row)
            }
            if (row.Order) {
                
                if (row.Order.payments.find(x => x.status === 'PAID')) return 'Matriculado';
                else if (row.Order.payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]?.payment_type === 'interest') return 'Interesse';
                return 'Iniciou pagamento';
            }
            return type === 'display' ? 'Não iniciou pagamento.' : 'Nao iniciou pagamento.';
        }
    },
    { title: 'Ações' },
    {
        title: 'Ligações',
        render: (data, type, row) => {
            if (userType !== 'admin') return '';
            let string = '<div class="my-2">';
            row.Calls.forEach(call => {
                string += `<div class="text-wrap width-200"><b>${moment(call.createdAt).format('DD/MM/YYYY hh:mm')}</b> - ${call.caller.name} - ${call.description}</div>`
            });
            string += '</div>';
            return string;
        },
    },
    {
        title: 'Observações',
        render: (data, type, row) => { return "<div class='text-wrap width-200'>" + row.observations + "</div>"; },
    },
];

const columnDefs = [
    {
        targets: 3,
        visible: userType === 'admin'
    },
    {
        targets: 4,
        visible: userType === 'admin'
    },
    {
        targets: 5,
        visible: userType === 'admin'
    },
    {
        targets: 6,
        visible: userType === 'admin'
    },
    {
        targets: -2,
        visible: userType === 'admin'
    },
    {
        targets: -1,
        visible: userType === 'admin'
    },
    {
        targets: -3,
        data: null,
        visible: userType === 'admin',
        defaultContent: '<a  class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon change-scholarship"></i></span></a>' +
            '<a  class=" action-button" data-toggle="modal"><i class="fas fa-paperclip action-icon edit-observations"></i></span></a>' +
            '<a  class=" action-button btn-link"><span class="text-primary btn-register-call">Cadastrar ligação</span></a>'
    }
];

export default class Students extends Component {
    constructor(props) {
        super(props)

        this.state = {
            students: [],
            loading: true,
            changeScholarshipModalOpen: false,
            showCallModal: false,
            showObservationsModal: false,
            showCalleds: false,
            showToCall: false,
            showInterested: false,
            requesting: false,
            user_id: null,
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update, toCall = false, alreadyCalled = false) => {
        try {
            this.setState({ requesting: true });
            let url = toCall === true ? '/report/students/to_call' : alreadyCalled === true ? '/report/students/called' : '/report/students';
            console.log(url)
            let response = await Api.get(url);
            let studentsFinal = [];
            
            response.data.students.forEach(student => {
                // if(student.Order === null && student.Order?.payments.length === 0) return studentsFinal.push(student);
                if (student.Orders.length === 0) return studentsFinal.push(student);
                if (this.state.showInterested) {
                    student.Orders.forEach(order => {
                        if (order.payments?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]?.payment_type === 'interest') {
                            studentsFinal.push({
                                ...student,
                                Order: order,
                                Orders: null
                            })
                        }
                       else if (!student.Orders) {
                            studentsFinal.push(student);
                        }
                    });
                } else if (this.state.showInterested) {
                    if (student.Orders) {
                        student.Orders.forEach(order => {
                            if (order.payments.length === 0) {
                                studentsFinal.push({
                                    ...student,
                                    Order: order,
                                    Orders: null
                                })
                            }
                        });
                    } else {
                        studentsFinal.push(student);
                    }
                } 
                else {
                    student.Orders.forEach(order => {
                        studentsFinal.push({
                            ...student,
                            Order: order,
                            Orders: null
                        })
                    });
                }
            });

            let sortedStudents = [...studentsFinal];

            sortedStudents.sort((a, b) => {
                const dateA = new Date(a.Order ? a.Order.updatedAt : a.updatedAt);
                const dateB = new Date(b.Order ? b.Order.updatedAt : b.updatedAt);
                return dateB - dateA;
            });

            sortedStudents.map(student => {
                if (student.Order) {
                    student.Order.payments = student.Order?.payments.filter(x => x.user_id === student.Order.UserId);
                    return student;
                }
                return student;
            });
            
            this.setState({ requesting: false });
            this.setState({ students: sortedStudents, loading: false });
            if (update === true) {
                this.table.reloadTableData(sortedStudents);
            }
        } catch (error) {
            this.setState({ requesting: false });
            console.log(error)
            toast.error('Erro ao buscar dados.')
        }
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('change-scholarship')) {
            if (!data.Order) return alert('Usuário não matriculado.')
                console.log(data)
            this.openScholarshipModal(data.Order.id);
        }
        if (e.target.className.includes('btn-register-call')) {
            this.toggleCallModal(data.id)
        }
        else if (e.target.className.includes('edit-observation')) {
            this.toggleObservationsModal(data.id);
        }
    }

    openScholarshipModal = (orderId) => {
        this.setState({ orderId }, this.toggleScholarshipChangeModal)
    }

    handleSuccess = (message) => {
        this.setState({ showObservationsModal: false, showCallModal: false, showCalleds: false, showToCall: false, changeScholarshipModalOpen: false, loading: true });
        this.getData(true);
        toast.success(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    toggleScholarshipChangeModal = () => {
        if (this.state.changeScholarshipModalOpen) {
            this.setState({ orderId: null });
        }
        this.setState({ changeScholarshipModalOpen: !this.state.changeScholarshipModalOpen })
    }

    toggleObservationsModal = (studentId) => {
        if (this.state.showObservationsModal) {
            this.setState({ studentId: null });
        }
        this.setState({ studentId, showObservationsModal: !this.state.showObservationsModal })
    }

    toggleCallModal = (studentId) => {
        if (this.state.showCallModal) {
            this.setState({ studentId: null });
        }
        this.setState({ studentId, showCallModal: !this.state.showCallModal })
    }

    toggleToCall = () => {
        this.setState({ showCalleds: false, showToCall: !this.state.showToCall,  }, () => {
            this.getData(true, this.state.showToCall, false);
        });
    }

    toggleCalleds = () => {
        this.setState({ showToCall: false, showCalleds: !this.state.showCalleds }, () => {
            this.getData(true, false, this.state.showCalleds)
        });
    }

    toogleToInterested = () => {
        this.setState({ students: [], loading: false,  changeScholarshipModalOpen: false, showCallModal: false,
            showObservationsModal: false, showCalleds: false, showToCall: false, showInterested: true, requesting: false,
        }, () => {
            this.getData(true);
        });
    }

    toggleClearFilters = () => {
        this.setState({ students: [], loading: false,  changeScholarshipModalOpen: false, showCallModal: false,
            showObservationsModal: false, showCalleds: false, showToCall: false, showInterested: false, requesting: false,
        }, () => {
            this.getData(true);
        });
    }

    // this.state = {
    //     students: [],
    //     loading: true,
    //     changeScholarshipModalOpen: false,
    //     showCallModal: false,
    //     showObservationsModal: false,
    //     showCalleds: false,
    //     showToCall: false,
    //     showInterested: false,
    //     requesting: false,
    //     user_id: null,
    // }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <>
                {userType === 'admin'
                    ?
                    <div className="row py-2">
                        <div className="col-auto">
                            <button onClick={this.toggleCalleds} className={`btn-neutral btn-call-filter ${this.state.showCalleds ? 'active' : ''}`}><span>Já liguei</span></button>
                        </div>
                        <div className="col-auto">
                            <button onClick={this.toggleToCall} className={`btn-neutral btn-call-filter ${this.state.showToCall ? 'active' : ''}`}><span>Para ligar</span></button>
                        </div>
                        <div className="col-auto">
                            <button onClick={this.toogleToInterested} className={`btn-neutral btn-call-filter ${this.state.showInterested ? 'active' : ''}`}><span>Interessados / Cadastrados</span></button>
                        </div>
                        <div className="col-auto">
                            <button onClick={this.toggleClearFilters} className="btn-neutral btn-call-filter"><span>Todos</span></button>
                        </div>
                        {
                            this.state.requesting ?
                                <div className="col-auto">
                                    <span>Buscando dados...</span>
                                </div>
                                :
                                null
                        }
                    </div>
                    :
                    null
                }
                <div className="mt-2 w-100">
                    <DataTable
                        data={this.state.students}
                        columns={columns}
                        options={{
                            buttons: ['pdf', 'print'],
                            responsive: true,
                            columnDefs: columnDefs,
                            destroy: true
                        }}
                        handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                        ref={ref => this.table = ref}
                    />
                    <Modal
                        isOpen={this.state.changeScholarshipModalOpen}
                        shouldCloseOnEsc={true}
                        orderId={this.state.orderId ? this.state.orderId : null}
                        user_id={this.state.user_id}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={this.toggleScholarshipChangeModal}
                        onSuccess={message => { this.handleSuccess(message) }}
                        onError={message => { this.handleError(message) }}
                    />
                    <CallModal
                        isOpen={this.state.showCallModal}
                        shouldCloseOnEsc={true}
                        studentId={this.state.studentId}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={this.toggleCallModal}
                        onSuccess={message => { this.handleSuccess(message) }}
                        onError={message => { this.handleError(message) }}
                    />
                    <ObservationModal
                        isOpen={this.state.showObservationsModal}
                        shouldCloseOnEsc={true}
                        studentId={this.state.studentId}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={this.toggleObservationsModal}
                        onSuccess={message => { this.handleSuccess(message) }}
                        onError={message => { this.handleError(message) }}
                    />
                </div>
            </>
        )
    }
}
